.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h2 {
  color: #BF0052;
}

.App-header {
  background-color: #83C77C;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

hr {
  color: white;
  background-color: white;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border-top-width: 0px;
}

.App {
  background-color: #e3e3e3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header {
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #BF0052;
  background-color: black;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}

.footer {
  position: relative;
  bottom: 0;
    width: 100%;
    /* height: 8vh; */
     display: flex; 
/*     align-items: center; */
     justify-content: center; 
    border-top: 2px solid #BF0052;
    background-color: black;
    /* box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2); */
    font-size: 14px !important;
}

.footerInner {
  width: unset;
  text-align: left;
  margin-bottom: 0.5em;
}

.footerInner button {
  width: unset;
}

.footerInner p {
  margin: 0;
}

.pHeader {
  color: #BF0052;
  font-weight: bold;
  margin-top: 0.5em !important;
  margin-bottom: 0.5m !important;
}

.box {
  background-color: black;
  width: 10em;
  padding-bottom: 1em;
}

.goalBlock {
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.goalBody {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.goalBody input {
  width: 20%;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid white;
  height: 12em;
  width: 12em;
}

.listItemSelect {
  display: block;
}

.listeItemSelect p {
  width: 100%;
}

.listItem:hover{
  cursor: pointer;
}

.listItem span {
  font-size: 16px;
  display: flex;
}

.listLabel span{
  width: 100%;
}

.listlabelCheck {
  width: unset !important;
  flex-wrap: unset !important;
}

.listlabelCheck img {
  max-width: 50px !important;
  max-height: 50px !important;
}

.content {
  display: flex;
  width: 50em;
  align-items: flex-start;
  justify-content: center;
  /*flex-wrap: wrap;*/
  margin-top: 1em;
  position: relative;
  min-height: 70vh;
  flex-wrap: wrap;
}

.tabpanel {
  display: flex;
  flex-wrap: wrap;
}

.boxMargin {
  margin: 1em !important;
}

input[type="checkbox"] {
  margin: 0;
  margin-right: 1em;
  width: 20px;
  height: 20px;
}

label:hover {
  cursor: pointer;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

.headerParagraph {
  /*background-color: #83C77C;*/
}

a {
  text-decoration: none;
}

.goalBox {
  display: flex;
  flex-wrap: wrap;
  max-width: 65em;
  margin-bottom: 1em;
}

.goalBox h2 {
  width: 100%;
  text-align: left;
  margin: 0;
}

.goalBox .listItem {
  /*width: 10em;*/
}

.personaBox {
    display: flex;
    flex-wrap: wrap;
}

.personaBox h2 {
  text-align: left;
  margin: 0;
}

.personaBox .listItem {
  width: 10em;
}

.personaHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.goalHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.personaHeader span{
  color: black;
}

.goalHeader span{
  color: black;
}

.personaHeader span:hover{
  cursor: pointer;
  color: #BF0052;
}

.goalHeader span:hover{
  cursor: pointer;
  color: #BF0052;
}

.personaImg {
  height: 5em;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.uploadBox {
  /*display: flex;*/
  width: 20em;
  margin-top: 1em;
}

.alterSelect {
  align-items: normal !important;
}

.alterSelect img {
  max-width: unset !important;
}

.alterMulti {
  align-items: normal !important;
  flex-wrap: nowrap !important;
  height: unset !important;
}

.alterMulti img {
 /* max-width: 100% !important;*/
}

.choiceBox {
  width: 10em;
  height: 12em;
  background-color: black;
  margin: 1em;
  color: white;
  text-align: left;
  padding: 1em;
}

.choiceBox:hover {
  background-color: #BF0052;
}

.choiceBox p {
  font-size: 16px;
}

.choiceBox img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 80px
}

.videoHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.videoHeader h2 {
  width: 100%;
  margin-bottom: 0;
}

.videoBody h2 {
  margin: 0;
}

.choiceBoxInterface {
  width: 25em;
  background-color: black;
  color: white;
  text-align: left;
  padding: 1em;
/*  font-size: 16px;*/
  margin: 2px;
  text-align: center;
}

.choiceBoxInterface:hover {
  background-color: #BF0052;
  cursor: pointer;
}

.choiceBoxVid {
  width: 10em;
  background-color: black;
  color: white;
  text-align: left;
  padding: 0.5em;
  font-size: 16px;
  margin: 2px;
}

.choiceBoxVid:hover {
  background-color: #BF0052;
  cursor: pointer;
}

.choiceBoxVid p {
  font-size: 16px;
}

.optionsBlock {
  margin: 1em;
  position: relative;
/*  min-height: 40vh;*/
}

.resultBox {
  width: 10em;
  height: 10em;
  background-color: black;
  margin: 1em;
  color: white;
  text-align: left;
  padding: 1em;
}

.resultBox p {
  font-size: 16px;
}

.startRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.startRow p{
  margin: 0.5em;
}


.resultBoxPit {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: gray;
/*  width: 20em;
  height: 20em;*/
}

.pit {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7em;
  width: 7em;
  background-color: gray;
  border-radius: 50%;
  margin: 1em;
  position: relative;
}

.pit img {
  height: 7em;
  position: absolute;
}

.activePit {
  background-color: #83C77C;
  border: 2px solid orange;
}

.soundPit {
  background-image:url('music-note.png');
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  background-color: yellow;
}

.resultBoxLog {
  text-align: left;
  padding: 1em;
  /*height: fit-content;*/
  width: 20em;
  overflow-y: scroll;
}

.resultBoxLog p {
  font-size: 16px;
  margin: 0;
  margin-right: 5em;
}

.bigLogs {
  border-bottom: 1px solid white;
}

.resultBoxLog div {
  display: flex;
}

.selectItem {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

button {
  background-color: #BF0052 !important;
  width: 100%;
}

button .MuiButton-label {
  text-decoration: underline;
}

.settingsBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.settingsBody span {
  width: 100%;
  text-align: left;
  font-size: 12px;
  padding-left: 15px;
  padding-bottom: 5px;
}

.settingsBody h4 {
  width: 100%;
}

.innerHeader{
  margin: 0;
  color: #BF0052;
}

.listItem{
  margin: 4px;
  background-color: black;
  border-radius: 4px;
  border: 1px solid #fff;
  overflow: hidden;
  float: left;
}

.listItem img {
  /*max-width: 100px;*/
  max-height: 100px;
}

.listLabel {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.listLabel img{
  max-width: 100px;
  max-height: 100px;
}

.listItem label {
    float: left;
    line-height: 3.0em;
    width: 100%;
    height: 100%;
      display: flex;
  justify-content: center;
  align-items: center;
}

.listItem label span {
  text-align: center;
  padding: 3px 0;
  display: block;
}

.listItem label input {
  position: absolute;
  display: none;
  color: #fff !important;
}
/* selects all of the text within the input element and changes the color of the text */
.listItem label input + span{color: #fff;}


/* This will declare how a selected input will look giving generic properties */
.listItem input:checked + span {
    color: #ffffff;
    text-shadow: 0 0  6px rgba(0, 0, 0, 0.8);
}

.listItem input:checked + .listLabel{background-color: #BF0052;}

.MuiTab-textColorSecondary {
  color: white !important;
  background-color: black !important;
}

.MuiTab-textColorSecondary.Mui-selected {
    color: #f50057 !important;
}

.MuiBox-root-2 {
  width: -webkit-fill-available !important;
}

.MuiTab-textColorSecondary.Mui-disabled {
  opacity: 0.9;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
    visibility: hidden;
    min-width: 250px;
    /* margin-left: -125px; */
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    /* left: 50%; */
    bottom: 30px;
    font-size: 20px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
